class ChatOptions {
  constructor(options) {
    const {
      chatId,
      language,
      languageCode,
      mode,
      voice,
      voiceProvider,
      voiceGender,
      voiceSpeed,
      model,
      clientProvider,
      streamingEnabled = false,
      autoRecord = false,
      autoSend = false,
      autoCorrect = false,
      debug = false,
      messageCount,
      transcriptionMode = 'v1',
      aiModel = 'v1',
      dialectCode,
      voiceModel,
      beta_features_enabled = false
    } = options;

    this.chatId = chatId;
    this.language = language;
    this.languageCode = languageCode;
    this.dialectCode = dialectCode;
    this.mode = mode;
    this.voice = voice;
    this.voiceProvider = voiceProvider;
    this.voiceGender = voiceGender;
    this.voiceSpeed = voiceSpeed;
    this.streamingEnabled = streamingEnabled;
    this.autoRecord = autoRecord;
    this.autoSend = autoSend;
    this.autoCorrect = autoCorrect;
    this.debug = debug;
    this.messageCount = messageCount;
    this.model = model;
    this.clientProvider = clientProvider;
    this.chatClientProviderOptions = [
      { label: 'Anthropic', value: 'anthropic' },
      { label: 'OpenAI', value: 'openai' },
      { label: 'Azure', value: 'azure'},
      { label: 'Groq', value: 'groq'}
    ]
    this.chatModelOptions = {
      anthropic: [
        { label: 'Claude 3 Opus', value: 'claude-3-opus-20240229' },
        { label: 'Claude 3 Sonnet', value: 'claude-3-sonnet-20240229' },
        { label: 'Claude 3.5 Sonnet', value: 'claude-3-5-sonnet-latest' },
        { label: 'Claude 3 Haiku', value: 'claude-3-haiku-20240307' }
      ],
      openai: [
        { label: 'GPT-4o', value: 'gpt-4o-2024-08-06' },
        { label: 'GPT-4 Turbo', value: 'gpt-4-turbo' },
        { label: 'GPT-4 Turbo Preview', value: 'gpt-4-turbo-preview' },
        { label: 'GPT-4 1106 Preview', value: 'gpt-4-1106-preview' },
        { label: 'GPT-4', value: 'gpt-4' }
      ],
      azure: [
        { label: 'GPT-4o', value: 'gpt-4o-2024-08-06' },
        { label: 'GPT-4 Turbo', value: 'gpt-4-turbo' },
        { label: 'GPT-4 Turbo Preview', value: 'gpt-4-turbo-preview' },
        { label: 'GPT-4 1106 Preview', value: 'gpt-4-1106-preview' },
        { label: 'GPT-4', value: 'gpt-4' }
      ],
      groq: [
        { label: 'Llama 3.1 405B (Preview)', value: 'llama-3.1-405b-reasoning' },
        { label: 'Llama 3.1 70B (Preview)', value: 'llama-3.1-70b-versatile' },
        { label: 'Llama 3.1 8B (Preview)', value: 'llama-3.1-8b-instant' },
        { label: 'Meta Llama 3 70B', value: 'llama3-70b-8192' }
      ]
    }
    this.audioRecordingService;
    this.audioPlayerService;
    this.selectedMicrophone = null;
    this.selectedSoundOutput = null;
    this.chatTranscriptionModes = [
      { label: 'V1: understands any language, may hallucinate', value: 'v1' },
      { label: 'V2: live transcription (not multilingual)', value: 'v2' }
    ];

    if (beta_features_enabled === 'true') {
      this.chatTranscriptionModes.push(
        { label: 'V3 (turbo, loads faster)', value: 'v3' },
        { label: 'V4 (Gemini Flash, experimental)', value: 'v4' },
        { label: 'V5 (Gemini Pro, experimental)', value: 'v5' }
      );
    }

    this.transcriptionMode = transcriptionMode;
    this.aiModelOptions = [
      { label: 'V1 (recommended, smartest)', value: 'v1' },
      { label: 'V2 (use if you see errors with V1)', value: 'v2' }
    ];
    this.aiModel = aiModel; // Default to V1
    this.voiceModel = voiceModel;
    this.voiceModelOptions = [
      { label: 'V1 (beta model, loads faster)', value: 'v1' },
      { label: 'V2 (use for most reliable pronunciation)', value: 'v2' },
    ];
    this.canChangeVoiceModel = this.language === 'spanish';
  }

  setupEventListeners() {
    // Bind the event listener methods to the instance
    if (this.debug) {
      this.handleLanguageChange = this.handleLanguageChange.bind(this);
      this.handleModeChange = this.handleModeChange.bind(this);
      this.handleVoiceChange = this.handleVoiceChange.bind(this);
      this.handleVoiceProviderChange = this.handleVoiceProviderChange.bind(this);
      this.handleVoiceGenderChange = this.handleVoiceGenderChange.bind(this);
      this.handleModelChange = this.handleModelChange.bind(this);
      this.handleClientProviderChange = this.handleClientProviderChange.bind(this)
    }
    this.handleVoiceSpeedChange = this.handleVoiceSpeedChange.bind(this);
    this.handleStreamingEnabledChange = this.handleStreamingEnabledChange.bind(this);
    this.handleAutoRecordChange = this.handleAutoRecordChange.bind(this);
    this.handleAutoSendChange = this.handleAutoSendChange.bind(this);
    this.handleAutoCorrectChange = this.handleAutoCorrectChange.bind(this);
    this.handleMicrophoneChange = this.handleMicrophoneChange.bind(this);
    this.handleSoundOutputChange = this.handleSoundOutputChange.bind(this);
    this.handleTranscriptionModeChange = this.handleTranscriptionModeChange.bind(this);
    this.handleAiModelChange = this.handleAiModelChange.bind(this);
    this.handleVoiceModelChange = this.handleVoiceModelChange.bind(this);

    // Add event listeners to the form inputs
    if (this.debug) {
      document.getElementById('chatLanguage').addEventListener('input', this.handleLanguageChange);
      document.getElementById('chatMode').addEventListener('input', this.handleModeChange);
      document.getElementById('chatVoice').addEventListener('input', this.handleVoiceChange);
      document.getElementById('chatVoiceProvider').addEventListener('input', this.handleVoiceProviderChange);
      document.getElementById('chatVoiceGender').addEventListener('input', this.handleVoiceGenderChange);
      document.getElementById('chatStreamingEnabled').addEventListener('change', this.handleStreamingEnabledChange);
      document.getElementById('chatClientProvider').addEventListener('change', this.handleClientProviderChange);
      document.getElementById('chatModel').addEventListener('change', this.handleModelChange);

      // Add event listeners to the buttons
      document.getElementById('chatVoiceSpeedSlow').addEventListener('click', (event) => {
        this.voiceSpeed = 'slow';
        $('.js-chat-voice-speed').removeClass('active');
        event.target.classList.add('active');
      });
      document.getElementById('chatVoiceSpeedStandard').addEventListener('click', (event) => {
        this.voiceSpeed = 'standard';
        $('.js-chat-voice-speed').removeClass('active');
        event.target.classList.add('active');
      });
      document.getElementById('chatVoiceSpeedFast').addEventListener('click', (event) => {
        this.voiceSpeed = 'fast';
        $('.js-chat-voice-speed').removeClass('active');
        event.target.classList.add('active');
      });

    }
    // document.getElementById('chatVoiceSpeed').addEventListener('input', this.handleVoiceSpeedChange);
    document.getElementById('chatAutoRecord').addEventListener('change', this.handleAutoRecordChange);
    document.getElementById('chatAutoSend').addEventListener('change', this.handleAutoSendChange);
    document.getElementById('chatAutoCorrect').addEventListener('change', this.handleAutoCorrectChange);
    document.getElementById('chatMicrophone').addEventListener('change', this.handleMicrophoneChange.bind(this));
    document.getElementById('chatSoundOutput').addEventListener('change', this.handleSoundOutputChange.bind(this));
    document.getElementById('chatTranscriptionMode').addEventListener('change', this.handleTranscriptionModeChange);
    document.getElementById('chatAiModel').addEventListener('change', this.handleAiModelChange);
    document.getElementById('chatVoiceModel').addEventListener('change', this.handleVoiceModelChange);

    // listen for modal close event
    $('#chatOptionsModal').on('hidden.bs.modal', this.closeModal);
  }

  setupInfoIconsEventListeners() {
    $('[data-toggle="tooltip"]').tooltip({
      trigger: 'hover click',
      placement: 'auto',
      container: 'body'
    });

    $('.js-info-icon').on('click touchstart', function(e) {
      e.preventDefault();
      e.stopPropagation();
    });

    // Handle both touch and click events
    $('[data-toggle="tooltip"]').on('touchstart click', function(e) {
      // Prevent double-firing on mobile devices
      if (e.type === 'click' && e.originalEvent.type === 'click') {
        return;
      }

      e.preventDefault();
      const $currentTooltip = $(this);

      // Hide all other tooltips first
      $('[data-toggle="tooltip"]').not(this).tooltip('hide');

      // Toggle current tooltip
      if ($currentTooltip.attr('aria-describedby')) {
        $currentTooltip.tooltip('hide');
      } else {
        $currentTooltip.tooltip('show');
      }
    });

    // Hide tooltip when clicking/touching anywhere else
    $(document).on('touchstart click', function(e) {
      if (!$(e.target).hasClass('js-info-icon') && !$(e.target).parents('.tooltip').length) {
        $('[data-toggle="tooltip"]').tooltip('hide');
      }
    });
  }

  handleLanguageChange(event) {
    this.updateOption('language', event.target.value);
  }

  handleModeChange(event) {
    this.updateOption('mode', event.target.value);
  }

  handleVoiceChange(event) {
    this.updateOption('voice', event.target.value);
  }

  // Event listener method for voice provider input change
  handleVoiceProviderChange(event) {
    this.updateOption('voiceProvider', event.target.value);
  }

  // Event listener method for voice gender input change
  handleVoiceGenderChange(event) {
    this.updateOption('voiceGender', event.target.value);
  }

  // Event listener method for voiceSpeed input change
  handleVoiceSpeedChange(event) {
    this.updateOption('voiceSpeed', event.target.value);
  }

  // Event listener method for streaming enabled checkbox change
  handleStreamingEnabledChange(event) {
    this.updateOption('streamingEnabled', event.target.checked);
    $('#streaming_enabled').val(this.streamingEnabled);
  }

  // Event listener method for auto record checkbox change
  handleAutoRecordChange(event) {
    this.updateOption('autoRecord', event.target.checked);
  }

  // Event listener method for auto send checkbox change
  handleAutoSendChange(event) {
    this.updateOption('autoSend', event.target.checked);
  }

  // Event listener method for auto correct checkbox change
  handleAutoCorrectChange(event) {
    this.updateOption('autoCorrect', event.target.checked);
  }

  handleClientProviderChange(event) {
    this.updateOption('clientProvider', event.target.value);
    this.updateModelOptions(event.target.value);
    $('#chat-client-provider').val(this.clientProvider);
  }

  handleAiModelChange(event) {
    const newAIModel = event.target.value;

    if (newAIModel === 'v1') {
      this.updateClientProviderAndModel(newAIModel, 'anthropic', 'claude-3-5-sonnet-latest');
    } else if (newAIModel === 'v2') {
      this.updateClientProviderAndModel(newAIModel, 'openai', 'gpt-4o-2024-08-06');
    }
  }

  updateModelOptions(clientProvider) {
    const chatModelOptions = this.chatModelOptions[clientProvider];
    const selectElement = document.getElementById('chatModel');

    // Clear existing options
    while (selectElement.firstChild) {
      selectElement.removeChild(selectElement.firstChild);
    }

    // Add new options
    chatModelOptions.forEach(option => {
      const newOptionElement = document.createElement('option');
      newOptionElement.value = option.value;
      newOptionElement.text = option.label;
      selectElement.appendChild(newOptionElement);
    });

    // Update the model value
    this.updateOption('model', chatModelOptions[0].value);
    $('#chat-model').val(this.model);
  }

  handleModelChange(event) {
    this.updateOption('model', event.target.value)
    $('#chat-model').val(this.model);
  }

  handleMicrophoneChange(event) {
    this.selectedMicrophone = event.target.value;
  }

  handleSoundOutputChange(event) {
    this.selectedSoundOutput = event.target.value;
    this.audioPlayerService.updateSoundOutput(this.selectedSoundOutput);
  }

  async populateDeviceDropdowns() {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const microphoneDropdown = document.getElementById('chatMicrophone');
      const soundOutputDropdown = document.getElementById('chatSoundOutput');

      const audioInputDevices = devices.filter(device => device.kind === 'audioinput');
      const audioOutputDevices = devices.filter(device => device.kind === 'audiooutput');

      // Use sets to track added groupIds
      const uniqueMicrophoneGroups = new Set();
      const uniqueSpeakerGroups = new Set();

      // Helper function to add options to a dropdown
      function addOption(dropdown, value, text, selectedValue) {
        const option = document.createElement('option');
        option.value = value;
        option.text = text;
        if (value === selectedValue) {
          option.selected = true;
        }
        dropdown.appendChild(option);
      }

      // Clear existing options
      microphoneDropdown.innerHTML = '';
      soundOutputDropdown.innerHTML = '';

      if (audioInputDevices.length === 0) {
        const option = document.createElement('option');
        option.text = 'Default';
        option.value = 'default'
        microphoneDropdown.appendChild(option);
      } else {
        // Determine the default microphone label
        const defaultMicrophone = audioInputDevices.find(device => device.deviceId === 'default') || audioInputDevices[0];
        const defaultMicrophoneLabel = defaultMicrophone ? `${defaultMicrophone.label}` : 'Default Microphone';

        if (defaultMicrophone.groupId !== undefined) {
          uniqueMicrophoneGroups.add(defaultMicrophone.groupId);
        }

        addOption(microphoneDropdown, 'default', defaultMicrophoneLabel, this.selectedMicrophone);

        audioInputDevices.forEach(device => {
          if (device.groupId !== undefined) {
            if (!uniqueMicrophoneGroups.has(device.groupId) && device.deviceId !== 'default') {
              uniqueMicrophoneGroups.add(device.groupId);
              addOption(microphoneDropdown, device.deviceId, device.label || `Microphone ${microphoneDropdown.length + 1}`, this.selectedMicrophone);
            }
          } else {
            addOption(soundOutputDropdown, device.deviceId, device.label || `Speaker ${soundOutputDropdown.length + 1}`, this.selectedSoundOutput);
          }
        });
      }

      if (audioOutputDevices.length === 0) {
        const option = document.createElement('option');
        option.text = 'System Default Speaker Device';
        option.value = 'default'
        soundOutputDropdown.appendChild(option);
      } else {
        // Determine the default speaker label
        const defaultSpeaker = audioOutputDevices.find(device => device.deviceId === 'default') || audioOutputDevices[0];
        const defaultSpeakerLabel = defaultSpeaker ? `${defaultSpeaker.label}` : 'Default Speakers';

        if (defaultSpeaker.groupId !== undefined) {
          uniqueSpeakerGroups.add(defaultSpeaker.groupId);
        }

        addOption(soundOutputDropdown, 'default', defaultSpeakerLabel, this.selectedSoundOutput);

        audioOutputDevices.forEach(device => {
          if (device.groupId !== undefined) {
            if (!uniqueSpeakerGroups.has(device.groupId) && device.deviceId !== 'default') {
              uniqueSpeakerGroups.add(device.groupId);
              addOption(soundOutputDropdown, device.deviceId, device.label || `Speaker ${soundOutputDropdown.length + 1}`, this.selectedSoundOutput);
            }
          } else {
            addOption(soundOutputDropdown, device.deviceId, device.label || `Speaker ${soundOutputDropdown.length + 1}`, this.selectedSoundOutput);
          }
        });

      }
    } catch (error) {
      console.error('Error enumerating devices:', error);
    }
  }

  handleTranscriptionModeChange(event) {
    this.updateOption('transcriptionMode', event.target.value);
    this.audioRecordingService.updateTranscriptionMode(event.target.value);
  }

  handleVoiceModelChange(event) {
    this.updateOption('voiceModel', event.target.value);
  }

  buildModal() {
    const modalHTML = `
      <div class="modal fade" id="chatOptionsModal" tabindex="-1" role="dialog" aria-labelledby="chatOptionsModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="chatOptionsModalLabel">Chat Options</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="form-group ml-2">
                  <label for="chatAiModel"><strong>AI model</strong></label>
                  <select class="form-control" id="chatAiModel">
                    ${this.aiModelOptions.map((option) => `
                      <option ${this.aiModel === option.value ? 'selected="selected"' : ''} value="${option.value}">${option.label}</option>`).join('')}
                  </select>
                </div>
                ${this.canChangeVoiceModel ? `
                  <div class="form-group ml-2">
                    <label for="chatVoiceModel"><strong>Voice model</strong></label>
                    <select class="form-control" id="chatVoiceModel">
                      ${this.voiceModelOptions.map((option) => `
                        <option ${this.voiceModel === option.value ? 'selected="selected"' : ''} value="${option.value}">${option.label}</option>`).join('')}
                    </select>
                  </div>
                ` : ''}
                <div class="form-group ml-2">
                  <label for="chatTranscriptionModeLabel"><strong>Preferred tech to transcribe what I say</strong></label>
                  <select class="form-control" id="chatTranscriptionMode">
                    ${this.chatTranscriptionModes.map((option) => `
                      <option ${this.transcriptionMode === option.value ? 'selected="selected"' : ''} value="${option.value}">${option.label}</option>`).join('')}
                  </select>
                </div>
                <div class="form-group">
                  <span id="chatAutoRecordLabel" class="m-2"><strong>Auto-record?</strong> 
                    <svg class="info-icon js-info-icon" style="fill: #047bf8; cursor: pointer;" data-toggle="tooltip" data-original-title="Recording starts after AI reply" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                      <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-144c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z"/>
                    </svg>
                  </span>
                  <label class="toggle-filters pull-right">
                    <input type="checkbox" class="form-check-input" id="chatAutoRecord" ${this.autoRecord ? 'checked' : ''}>
                    <span class="toggle-slider"></span>
                  </label>
                </div>
                <div class="form-group">
                  <span id="chatAutoSendLabel" class="m-2"><strong>Auto-send?</strong>
                    <svg class="info-icon js-info-icon" style="fill: #047bf8; cursor: pointer;" data-toggle="tooltip" data-original-title="Sends after a few seconds of silence" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                      <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-144c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z"/>
                    </svg>
                  </span>
                  <label class="toggle-filters pull-right">
                    <input type="checkbox" class="form-check-input" id="chatAutoSend" ${this.autoSend ? 'checked' : ''}>
                    <span class="toggle-slider"></span>
                  </label>
                </div>
                <div class="form-group">
                  <span id="chatAutoCorrectLabel" class="m-2"><strong>Auto-correct?</strong>
                    <svg class="info-icon js-info-icon" style="fill: #047bf8; cursor: pointer;" data-toggle="tooltip" data-original-title="See corrections without clicking the pencil icon" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                      <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-144c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z"/>
                    </svg>
                  </span>
                  <label class="toggle-filters pull-right">
                    <input type="checkbox" class="form-check-input" id="chatAutoCorrect" ${this.autoCorrect ? 'checked' : ''}>
                    <span class="toggle-slider"></span>
                  </label>
                </div>
                <div class="form-group ml-2">
                  <label for="chatMicrophone"><strong>Microphone</strong></label>
                  <select class="form-control" id="chatMicrophone"></select>
                </div>
                <div class="form-group ml-2">
                  <label for="chatSoundOutput"><strong>Speaker</strong></label>
                  <select class="form-control" id="chatSoundOutput"></select>
                </div>

                ${this.debug ? `

                <div class="form-group ml-2">
                  <label for="chatClientProvider"><strong>Client provider</strong></label>
                  <select class="form-control" id="chatClientProvider">
                  ${this.chatClientProviderOptions.map((option) => `
                    <option ${this.clientProvider === option.value ? 'selected="selected"' : ''} value="${option.value}">${option.label}</option>`).join('')}
                  </select>
                </div>

                <div class="form-group ml-2">
                  <label for="chatModel"><strong>Model</strong></label>
                  <select class="form-control" id="chatModel">
                  ${this.chatModelOptions[this.clientProvider].map((option) => `
                    <option ${this.model === option.value ? 'selected="selected"' : ''} value="${option.value}">${option.label}</option>`).join('')}
                  </select>
                </div>
                <div class="form-group d-none">
                  <label for="chatLanguage">Language</label>
                  <input type="text" class="form-control" id="chatLanguage" value="${this.language}">
                </div>
                <div class="form-group d-none">
                  <label for="chatMode">Mode</label>
                  <input type="text" class="form-control" id="chatMode" value="${this.mode}">
                </div>
                <div class="form-group d-none">
                  <label for="chatVoice">Voice</label>
                  <input type="text" class="form-control" id="chatVoice" value="${this.voice}">
                </div>
                <div class="form-group d-none">
                  <label for="chatVoiceProvider">Voice Provider</label>
                  <input type="text" class="form-control" id="chatVoiceProvider" value="${this.voiceProvider}">
                </div>
                <div class="form-group d-none">
                  <label for="chatVoiceGender">Voice Gender</label>
                  <input type="text" class="form-control" id="chatVoiceGender" value="${this.voiceGender}">
                </div>
                <div class="form-group d-none">
                  <span id="chatVoiceSpeedLabel" class="m-2">Voice Speed</span>
                  <div class="btn-group pull-right" role="group" aria-label="Voice Speed">
                    <button type="button" class="btn btn-secondary ${this.voiceSpeed === 'slow' ? 'active' : ''} js-chat-voice-speed btn-chat-voice-speed" id="chatVoiceSpeedSlow">
                      <i class="fa fa-snail"></i> Slow
                    </button>
                    <button type="button" class="btn btn-secondary ${this.voiceSpeed === 'standard' ? 'active' : ''} js-chat-voice-speed btn-chat-voice-speed" id="chatVoiceSpeedStandard">
                      <i class="fa fa-walking"></i> Standard
                    </button>
                    <button type="button" class="btn btn-secondary ${this.voiceSpeed === 'fast' ? 'active' : ''} js-chat-voice-speed btn-chat-voice-speed" id="chatVoiceSpeedFast">
                      <i class="fa fa-running"></i> Fast
                    </button>
                  </div>
                </div>
                <div class="form-group d-none">
                  <span id="chatStreamEnabledLabel" class="m-2">Streaming Enabled?</span>
                  <label class="toggle-filters pull-right">
                    <input type="checkbox" class="form-check-input" id="chatStreamingEnabled" ${this.streamingEnabled ? 'checked' : ''}>
                    <span class="toggle-slider"></span>
                  </label>
                </div>
                ` : ''}
              </form>
            </div>
          </div>
        </div>
      </div>
    `;

    // Append the modal HTML to the body
    document.body.insertAdjacentHTML('beforeend', modalHTML);
  }

  showModal() {
    $('#chatOptionsModal').modal('show');
  }

  async openModal() {

    if (this.audioRecordingService.isRecording) {
      this.audioRecordingService.stopRecording();
    }

    // Check if the modal already exists
    if (document.getElementById('chatOptionsModal')) {
      this.showModal()
    } else {
      this.buildModal();
      await this.populateDeviceDropdowns();
      this.showModal();
      this.setupInfoIconsEventListeners();
      this.setupEventListeners();
    }
  }

  closeModal() {
    // Hide the modal
    $('#chatOptionsModal').modal('hide');

    // Remove the modal HTML from the body
    document.getElementById('chatOptionsModal').remove();
  } 

  async updateOptions(options) {
    // Update local state
    Object.entries(options).forEach(([key, value]) => {
      this[key] = value;
    });

    try {
      const snakeCaseOptions = Object.entries(options).reduce((acc, [key, value]) => {
        acc[this.toSnakeCase(key)] = value;
        return acc;
      }, {});

      const response = await fetch(`/account/chat_settings/${this.chatId}`, {
        method: 'PUT',
        headers: {
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ chat: snakeCaseOptions }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      this.showNotification('Settings updated successfully!', 'warning');
      return result;
    } catch (error) {
      console.error('Error updating chat settings:', error);
      this.showNotification('Failed to update settings. Please try again.', 'error');
    }
  }

  async updateOption(key, value) {
    return this.updateOptions({ [key]: value });
  }

  updateClientProviderAndModel(aiModel, newClientProvider, newModel) {
    this.updateOptions({
      ai_model: aiModel,
      clientProvider: newClientProvider,
      model: newModel
    });
    $('#chat-client-provider').val(this.clientProvider);
    $('#chat-model').val(this.model);
  }

  toSnakeCase(str) {
    return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
  }

  showNotification(message, type = 'success') {
    const notificationElement = document.createElement('div');
    notificationElement.className = `alert alert-${type} alert-dismissible fade show`;
    notificationElement.role = 'alert';
    notificationElement.innerHTML = `
      ${message}
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    `;

    const modalBody = document.querySelector('#chatOptionsModal .modal-body');
    if (modalBody) {
      modalBody.insertAdjacentElement('afterbegin', notificationElement);

      // Automatically remove the notification after 5 seconds
      setTimeout(() => {
        notificationElement.remove();
      }, 2000);
    }
  }

}

export default ChatOptions;
